<template>
    <div class="vertFlex" >
       
        <div class="vertFlex">
            <div v-if="transactionSummary!=null">
                <div class="bordered" >
                    <div class="vertFlexTop">
                        <div v-html="transactionSummary" class="multilinelabel"></div>
                    </div>  
                </div>
            </div>
        </div>

        <div  v-if="showNextSteps">
             <tallbutton toplabel="Next Steps" @click="nextsteps()"/>

        </div>    

        <div v-if="!showNextSteps">
            <!-- get person and then show it -->
            <tallbutton :toplabel="confirmText" @click="confirm()"/>
        </div> 
    
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'initialconfirm',
    components:{
        tallbutton
    },
    mixins:[datamethods],
     props:{
        
       
        confirmText:{
            type:String,
            default:"Confirm"
        },
        skipIfNull:{
            type:Boolean,
            default:true
        },
        showNextSteps:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait',
            'accountTransactionId',
            'person'
        ]),
        MOBILEIDURL(){
            if(window.MOBILEID_URL==undefined){return ""}
            else{return window.MOBILEID_URL}
        },
        NEXTSTEPS_STEP3TEXT(){
            if(window.NEXTSTEPS_STEP3TEXT==undefined){return ""}
            else{return window.NEXTSTEPS_STEP3TEXT}
        },
        NEXTSTEPS_STEP4TEXT(){
            if(window.NEXTSTEPS_STEP4TEXT==undefined){return ""}
            else{return window.NEXTSTEPS_STEP4TEXT}
        },
    },
    data(){
        return{
            transactionSummary:null
        }
    },
    methods:{
         async GetTransSummary(){
            let req = {
                accountTransactionId:this.accountTransactionId
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'transsummary')

            //handle response
            if(response.Successful){
                this.transactionSummary = response.StringResponse

                this.replaceAddress()

                if(this.skipIfNull && this.transactionSummary==null){
                    this.confirm() //out!
                }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        replaceAddress(){
            if(this.transactionSummary==null){return}
            if(this.transactionSummary.includes('[Address]')){
                var fullAddress=""
                if(this.person!=undefined){
                    
                    if(this.person.AddressLine1!=undefined){
                        fullAddress= this.person.addressLine1 + ' ' + this.person.city + ', ' + this.person.stateName + ' ' + this.person.zipCode
                    }
                }
                this.transactionSummary=this.transactionSummary.replace('[Address]',fullAddress)
            }
        },
        confirm(){
            
            this.$emit('confirm')
        },
        nextsteps(){
            this.$emit('nextsteps')
        }
    },
    mounted(){
        this.GetTransSummary()
    }
    

}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
</style>