<template>
  <div class="outerDiv" :style="configStyle">
        <svheader @logout="logout()" class="header"/>

        <div class="mainDiv">
 
            <h1><label class="title" v-if="!loggedIn">ID Station Mobile Payment System</label></h1>

            <div v-html="loginMessageAlert" class="htmlLabel"></div>

            <loginuser ref="loginuser" v-if="!loggedIn" @login="logmein" @signup="signUp" />


            <div v-if="loggedIn" class="titlepanelfoot" >

                <div >
                    <div>
                        <h1><label class="title">{{title}}</label></h1>
                    </div>
                    
                    <label class="subtitleMain" v-if="subtitle!=''">{{subtitle}}</label>
                    <label class="subtitleMain"  v-if="subtitle2!=''">{{subtitle2}}</label>
                </div>
                
                
                <div class="panel">
                     <div v-if="pleaseWait">
                        <label class="pleasewait">Please Wait...</label>
                    </div>
                    <signinsignup v-if="currentPanel=='signinsignup'" @signin="signIn" @signup="signUp"/>
                    <location v-if="currentPanel=='location'" @select="selectLocation"/>
                    <urlproduct v-if="currentPanel=='urlproduct'" @continue="continueUrlProduct"/>
                    <accountproduct v-if="currentPanel=='accountproduct'" @continue="continueAccountProduct"/>
                    <producttype v-if="currentPanel=='producttype'" @select="selectproducttype"/>
                    <product ref="productscreen" v-if="currentPanel=='product'" :typeGUID="selectedProductTypeGuid" @select="selectproduct"/>
                    <initialconfirm v-if="currentPanel=='initialconfirm'"  @confirm="confirmtrans" :skipIfNull="paramTrue"/>
                    <personwallet v-if="currentPanel=='person'" :requireAddress="requireAddress" :requireEmail="requireEmail" 
                            :requireName="requireName" :requirePassword="requirePassword" :requirePhone="requirePhone" :addBadge="addBadge"
                            :requireBirthdate="requireBirthdate" :requireID="requireID"  @saved="saveperson"/>

                
                    <getpicture ref="getpicture_person" v-if="currentPanel=='picture'" buttonClass="pbutton"  :allowFileSelect="allowFileSelect"  :allowFileSelectIfNoCapture="paramTrue"
                        :showImage="paramFalse" @gotpic="gotImage_person" :confirmRequirements="paramTrue" :havePic="havepic_person" 
                        :imageStorageName="storageName_person" :defaultImageName="pictureurl_person" :requirePicture="paramTrue" @havepic="pic_person"/> 
                     <getpicture ref="getpicture_vac" v-if="currentPanel=='picture_vac'" buttonClass="pbutton"  :allowFileSelect="allowFileSelect"  :allowFileSelectIfNoCapture="paramTrue"
                        :showImage="paramFalse" @gotpic="gotImage_vac" :confirmRequirements="paramFalse"  :havePic="havepic_vac" 
                        :imageStorageName="storageName_vac" :defaultImageName="pictureurl_vac" :requirePicture="paramFalse"  @havepic="pic_vac"/>  
                    <getpicture ref="getpicture_dlf" v-if="currentPanel=='picture_dlf'" buttonClass="pbutton"  :allowFileSelect="allowFileSelect"  :allowFileSelectIfNoCapture="paramTrue"
                        :showImage="paramFalse" @gotpic="gotImage_dlf" :confirmRequirements="paramFalse"  :havePic="havepic_dlf" 
                        :imageStorageName="storageName_dlf" :defaultImageName="pictureurl_dlf" :requirePicture="requireDL"  @havepic="pic_dlf"/> 
                    <getpicture ref="getpicture_dlb" v-if="currentPanel=='picture_dlb'" buttonClass="pbutton"  :allowFileSelect="allowFileSelect"  :allowFileSelectIfNoCapture="paramTrue"
                        :showImage="paramFalse" @gotpic="gotImage_dlb" :confirmRequirements="paramFalse"  :havePic="havepic_dlb" 
                        :imageStorageName="storageName_dlb" :defaultImageName="pictureurl_dlb" :requirePicture="requireDL_Back"  @havepic="pic_dlb"/> 

                    <card ref="cardscreen" v-if="currentPanel=='card'" @select="selectcard" @altpayment="altpayment" @multipay="multipay"/>
                    <multipay ref="multipayscreen" v-if="currentPanel=='multipay'" @complete="fundssubmitted"  @singlepay="singlepay"/>
                    <accountsummary v-if="currentPanel=='accountsummary'" :accountTransactionId="accountTransactionId" 
                        :selectedPaymentAmount="selectedPaymentAmount" :selectedWalletTokenId="selectedWalletTokenId" @fundssubmitted="fundssubmitted"/>
                    <initialconfirm v-if="currentPanel=='thanks'" @nextsteps="nextsteps" :showNextSteps="showNextSteps" @confirm="confirmthanks" :confirmText="confirmThanksText" :skipIfNull="paramFalse" />
                    <nextsteps v-if="currentPanel=='nextsteps'" @confirm="confirmthanks" :confirmText="confirmThanksText"/>
                    
                </div>
                
                <div>
                     <linkbutton class="backButton" v-if="showBack && !pleaseWait" @click="goBack()" :disabled="pleaseWait" toplabel="< Back"/>
                </div>
            </div>
            <div v-if="!message==''" class="vertFlex">
                <label class="responsemessage">{{message}}</label>
                <tallbutton toplabel="Click Here to Try Again" @click="tryAgain" v-if="message=='Your transaction failed to process.'"></tallbutton>
            </div>
            <a class="backButton" v-if="showMobileIDLink && showLink"  :href="MOBILEIDURL">I'm already signed up. Take me to my ID!</a>
        </div>
    
        <svfooter/>
        <GlobalEvents v-if="listenersConnected" target="window" @focus="refresh"/>
  </div>
</template>

<script>
import svheader from './header.vue'
import svfooter from './footer.vue'
import {mapFields} from 'vuex-map-fields'
import datamethods from '../services/datamethods.js'
import format from '../style/format.js'
import style from '../style/style.js'
import loginuser from './actions/loginuser.vue'
import linkbutton from './controls/linkbutton.vue'

import location from './screens/location.vue'
import producttype from './screens/producttype.vue'
import product from './screens/product.vue'
import initialconfirm from './screens/initialconfirm.vue'
import personwallet from './screens/person.vue'
import getpicture from './screens/picture.vue'
import card from './screens/card.vue'
import multipay from './screens/multipay.vue'
import accountsummary from './screens/accountsummary.vue'
import urlproduct from './screens/urlProduct.vue'
import signinsignup from './screens/signInSignUp.vue'
import accountproduct from './screens/accountProduct.vue'
import tallbutton from './controls/tallbutton.vue'
import nextsteps from './screens/nextsteps.vue'
export default {
    components:{
        svheader,
        svfooter,
        loginuser,
        linkbutton,

        location,
        producttype,
        product,
        initialconfirm,
        personwallet,
        getpicture,

        card,
        multipay,
        accountsummary,
        urlproduct,
        signinsignup,
        accountproduct,
        tallbutton,
        nextsteps
    },
    mixins:[datamethods, format,style],
    computed:{
        ...mapFields([
            'isAdminUser',
            'loggedInUser',
            'loggedIn',
            'requireLogin',
            'message',
            'pleaseWait',
            'robot',
            'logoOverride',
            'logoImageBasePath',
            'username',
            'oldpassword',
            'isUrlProduct',
            'selectedProgram',
            'selectedDate',
            'selectedTime',
            'selectedCost',
            'selectedDeposit',
            'selectedLocation',
            'isAccountProduct',
            'accountList',
            'accountTransactionId',
            'selectedPaymentAmount',
            'selectedProductGUID',
            'selectedProductId'
        ]),
        loginMessageAlert(){
            if(window.LOGIN_MESSAGE_ALERT==undefined){return ""}
            else{return window.LOGIN_MESSAGE_ALERT}
        },
        showNextSteps(){
            
            if(window.SHOW_NEXT_STEPS==undefined){return true}
            else{return window.SHOW_NEXT_STEPS}
        },
        allowMultipay(){
            if(window.ALLOW_MULTIPAY==undefined){return false}
            else{return window.ALLOW_MULTIPAY}
        },
        defaultMultipay(){
            if(window.DEFAULT_MULTIPAY==undefined){return false}
            else{return window.DEFAULT_MULTIPAY}
        },
        allowFileSelect(){
            var allow = window.ALWAYS_ALLOW_FILE_SELECT
            if(allow==undefined){
                allow=false
            }
            return allow
        },
        showMobileIDLink(){
            return !this.isAccountProduct && this.MOBILEIDURL!=''
        },
        showPersonData(){
            return window.SHOW_PERSON=="True" && ( this.requireAddress || this.requirePhone || this.requireEmail || this.requireName || this.requireBirthdate || this.requireID )
        },
        showGetVaccine(){
            return this.showPicture && window.SHOW_GETVACCINE=='True'
        },
        MOBILEIDURL(){
            if(window.MOBILEID_URL==undefined){return ""}
            else{return window.MOBILEID_URL
                    }
        },
        showGetDL(){
            
            return this.showPicture && window.SHOW_GETDL=='True'
        },
        showGetDL_Back(){
            
            return this.showPicture && window.SHOW_GETDL_BACK=='True'
        },
        requireDL(){
            if(window.REQUIRE_DL==undefined){return false}
            else{return window.REQUIRE_DL=="True"}
        },
        requireDL_Back(){
            if(window.REQUIRE_DL_BACK==undefined){return false}
            else{return window.REQUIRE_DL_BACK=="True"}
        },
        //images
        pictureurl_person(){
            if(this.url_person!=null){return this.url_person}
            else{return this.defaultImageName_person}
        }, 
         pictureurl_vac(){
            if(this.url_vac!=null){return this.url_vac}
            else{return this.defaultImageName_vac}
        }, 
         pictureurl_dlf(){
            if(this.url_dlf!=null){return this.url_dlf}
            else{return this.defaultImageName_dlf}
        },
        pictureurl_dlb(){
            if(this.url_dlb!=null){return this.url_dlb}
            else{return this.defaultImageName_dlb}
        },
        confirmThanksText(){
            if(this.addBadge){
                if(window.MOBILEID_URL==''){
                    return "Logout"
                }else{
                    return "Go to MobileID"
                }
                
            }else{
                return "Logout"
            }
        }
    },
    data(){
        return{
            listenersConnected:true,

            //state
            currentPanel:'splash',
            previousPanel:'splash',
            title:'',
            subtitle:'',
            subtitle2:'',
            showBack:false,
            showLink:false,

            //sel product, token
            selectedProductTypeGuid:null,
            
            selectedWalletTokenId:null,
            

            //show and require
            requireAddress:false,
            requirePhone:false,
            requireEmail:false,
            requireName:false,
            requirePassword:false,
            requireBirthdate:false,
            requireID:false,
            showPicture:false,
            showPayment:false,
            addBadge:false,

            //true false for comp params
            paramTrue:true,
            paramFalse:false,


            //images
            url_person:null,
            url_vac:null,
            url_dlf:null,
            url_dlb:null,
            havepic_person:false,
            havepic_vac:false,
            havepic_dlf:false,
            havepic_dlb:false, 
            storageName_person:'person',
            defaultImageName_person:require('@/assets/' + 'WM_personimage.png'), 
            storageName_vac:'vaccine',
            defaultImageName_vac:require('@/assets/' + 'WM_vaccinecard.png'), 
            storageName_dlf:'front',
            defaultImageName_dlf:require('@/assets/' + 'WM_licensefront.png'),
            storageName_dlb:'back',
            defaultImageName_dlb:require('@/assets/' + 'WM_licenseback.png'),

           urlProductOnly:window.URL_PRODUCT_ONLY,
           allowAlternativePayment:window.ALLOW_ALTERNATIVE_PAYMENT=="True",
            autoAlternativePayment:window.AUTO_ALTERNATIVE_PAYMENT,

        }
    },
    methods:{
        multipay(){
            
            this.GoTo('multipay')
        },
        singlepay(){
            this.GoTo('card')
        },
        tryAgain(){
            this.GoTo('pay')
        },
        GoTo(screen,previousScreen){
            //testing
             /* if (screen!='thanks'){
                this.currentPanel='nextsteps'
             this.title="Add Payments"
            this.subtitle=''
            this.subtitle2=''
            this.showBack=true
            this.showLink=false
            return;   
            }  */
         
            //testing

            console.log('goto ' + screen)
            if(previousScreen!=undefined) {this.previousPanel=previousScreen;}

            this.currentPanel=screen;
            this.showBack=false;
            this.showLink=false;
            switch (screen) {
                case 'signinsignup':
                    if(this.loggedIn){
                        this.GoTo('person')
                        return;
                    }

                    //titles
                    this.title="Payment Portal"
                    this.subtitle=''
                    this.subtitle2=''
                    this.showLink=true
                    break;
                case 'location':
                    //skip conditions
                    if(this.isAccountProduct){
                        this.loggedIn=false;
                        return;
                    }
                    //this is the old style transtech
                    /* if(this.isUrlProduct || this.urlProductOnly){
                        this.GoTo('urlproduct')
                        return;
                    } */


                    if(this.selectedProductGUID!=null){
                        this.getProductbyGUID()
                        return;
                    }
                    //added this for transtech style with location and product
                    if(this.urlProductOnly){
                        this.GoTo('urlproduct')
                        return;
                    }
                    if(this.selectedProductTypeGuid!=null){
                        GoTo('product');
                        return;
                    }
                    if(this.loggedInUser.LocationId>0){
                        this.GoTo('producttype')
                        return;
                    }
                    //titles
                    this.title='Select Location'
                    this.subtitle=''
                    this.subtitle2=''
                    this.showLink=true
                    break;
                case 'urlproduct':
                    //titles
                    this.title="Payment Portal"
                    this.subtitle=''
                    this.subtitle2=''
                    this.showLink=true
                    break;
                case 'accountproduct':
                    //titles
                    this.title="Pay My Account"
                    this.subtitle=''
                    this.subtitle2=''

                    break;
                case 'producttype':
                    //skip 
                    if(this.selectedProductTypeGuid!=null){
                        GoTo('product');
                        return;
                    }
                    if(this.selectedProductGUID!=null){
                        this.getProductbyGUID()
                        return;
                    }
                    //titles
                    this.title='Product Type'
                    this.subtitle='Select a type of product from the list below'
                    this.subtitle2=''
                    this.showLink=true
                    break;
                case 'product':
                    //skip condition

                    //titles
                    this.title='Product'
                    this.subtitle='Select a product from the list below'
                    this.subtitle2=''
                    this.showBack=true
                    this.showLink=true
                    break;
                case 'initialconfirm':
                    //skip condition
                    if(!this.showPayment){
                        this.GoTo('signinsignup')
                        return;
                    }
                    //titles
                    this.title='Confirm Transaction'
                    this.subtitle='Please confirm your information and the names of your accounts.'
                    this.subtitle2=''
                    this.showBack=true
                    break;
                case 'person':
                    //skip condition
                    if(!this.showPersonData){
                        this.GoTo('picture')
                        return;
                    }
                    //titles
                    this.title='Enter your Information'
                    this.subtitle=''
                    this.subtitle2=''
                    this.showBack=true
                    break;
                case 'picture':
                    //skip condition
                    if(!this.showPicture){
                        this.GoTo('pay')
                        return;
                    }
                    //titles
                    this.title='ID Picture'
                    this.subtitle=''
                    this.subtitle2=''

                    //other init

                    break;
                case 'picture_vac':
                    //skip condition
                    if(!this.showGetVaccine){
                        this.GoTo('picture_dlf')
                        return;
                    }
                    //titles
                    this.title='Vaccine Card Picture'
                    this.subtitle=''
                    this.subtitle2=''
                    this.showBack=true
                    //other init
                  
                    break;
                case 'picture_dlf':
                    //skip condition
                    if(!this.showGetDL){
                        this.GoTo('pay')
                        return;
                    }
                    //titles
                    this.title="Driver's License Front"
                    this.subtitle=''
                    this.subtitle2=''
                    this.showBack=true
                    //other init
                 
                    break;
                case 'picture_dlb':
                    //skip condition
                    if(!this.showGetDL_Back){
                        this.GoTo('pay')
                        return;
                    }
                    //titles
                    this.title="Driver's License Back"
                    this.subtitle=''
                    this.subtitle2=''
                    this.showBack=true
                    //other init
                    
                    break;
                case 'pay':
                    //skip to multi if default
                    if( this.defaultMultipay  && this.allowMultipay){
                        this.GoTo('multipay')
                        return;
                    }
                    this.GoTo('card')
                    break;
                case 'card':
                    //skip condition
                    if(!this.showPayment){
                        this.GoTo('thanks')
                        return;
                    }
                    //also skip if allow alt and auto alternative
                    if(this.autoAlternativePayment && this.allowAlternativePayment){
                        this.showPayment=false
                        this.GoTo('thanks')
                        return;
                    }
                    

                    //titles
                    this.title="Add Payment Info"
                    this.subtitle='Please click a payment option below'
                    this.subtitle2=' to be directed to PCI-Compliant site where you can securely store your payment information -- your information will always be encrypted and will never be visible to the school or SchoolVision.'
                    this.showBack=true
                    break;
                case 'multipay':
                    //skip condition
                    if(!this.showPayment){
                        this.GoTo('thanks')
                        return;
                    }
                    //titles
                    this.title="Add Payments"
                    this.subtitle=''
                    this.subtitle2=''
                    this.showBack=true

                    this.$nextTick(()=>{
                        this.$refs.multipayscreen.initializeEnrollment()
              
                        });
                    

                    break;
                case 'accountsummary':
                    
                    //titles
                    this.title="Purchase Confirmation"
                    this.subtitle='Please review all of your information.'
                    this.subtitle2=''
                    this.showBack=true
                    break;
                case 'thanks':
                    //skip condition
                     

                    //titles
                    if(this.showPayment){
                        this.title='Thank you for your payment!'
                        this.subtitle=''
                        if(window.THANKS_MESSAGE_OVERRIDE!=null){
                            if(window.THANKS_MESSAGE_OVERRIDE!=''){
                                this.subtitle=window.THANKS_MESSAGE_OVERRIDE   
                            }
                        }
                        
                    }else{
                        //need to finish up, if no pmt
                        this.CompleteNoPay();


                        this.title='Thank you!'
                        this.subtitle='You will receive an email confirmation shortly.'
                        if(window.THANKS_MESSAGE_OVERRIDE_NOPAYMENT!=null){
                            if(window.THANKS_MESSAGE_OVERRIDE_NOPAYMENT!=''){
                                this.subtitle=window.THANKS_MESSAGE_OVERRIDE_NOPAYMENT   
                            }
                        }
                    }
                    
                    this.subtitle2=''
                    break;
                case 'nextsteps':
                    this.title='Next Steps'
                    this.subtitle=''
                        
                    break;
                default:
                    break;
            }
        },
        goBack(){
            if(this.previousPanel=='signinsignup'){this.previousPanel='location'}
           if(this.previousPanel=='producttype' && this.loggedInUser.ProductTypeKey_Default!=null){
                this.logout()
                return;
           }
           console.log('go back ' + this.previousPanel)
            this.GoTo(this.previousPanel);
            /* switch(this.currentPanel){
                case 'product':
                    this.GoTo('producttype')
                    break;
                 case 'initialconfirm':
                    this.GoTo('product');
                    break;
                case 'picture_dlb':
                    this.GoTo('picture_dlf')
                    break;
                case 'picture_vac':
                    this.GoTo('picture')
                    break;
                case 'picture_dlf':
                    this.GoTo('picture_vac')
                    break;
                case 'person':
                    break;
                case 'card':
                    break;
                default:
                    break;
            } */
        },

        signIn(){
            this.loggedIn=false
        },
        signUp(){
            this.loggedIn=true
            this.GoTo('person')
        },
        

        logmein(){
            if(this.isAccountProduct){
                this.saveperson()
            }else{
                if(this.loggedInUser.ProductTypeKey_Default!=null){
                    this.previousPanel='producttype'
                    this.selectedProductTypeGuid=this.loggedInUser.ProductTypeKey_Default
                    console.log('settype ' + this.selectedProductTypeGuid)
                    this.GoTo('product')
                }else
                {
                    this.GoTo('producttype')
                }
                
            }
            
            //this.GoTo('location')
        },
        selectLocation(loc){
            this.loggedInUser.LocationId=loc.id
            this.GoTo('producttype','location')
        },
        selectproducttype(productTypeGuid){
            this.selectedProductTypeGuid = productTypeGuid
            this.GoTo('product','producttype')
        },
        async selectproduct(product,accountTransactionId){
            this.selectedProductGUID = product.guid
            this.selectedProductId=product.id
            //handle the requirements for selected product
            this.requireAddress=product.requireAddress 
            this.requirePhone=product.requirePhone 
            this.requireEmail=product.requireEmail 
            this.requireName=product.requireName 
            this.showPicture=product.requirePicture 
            this.requirePassword=product.requirePassword
            this.requireBirthdate=product.requiresBirthdate
            this.requireID=product.requiresID
            this.showPayment=product.price>0 ? true:false
            this.selectedPaymentAmount=product.price
            this.addBadge = product.isBadgeProduct
            this.accountTransactionId=accountTransactionId
            this.GoTo('initialconfirm','product')

            
        },
        continueUrlProduct(){
            //set requirements for url product
            this.requireAddress=true
            this.requirePhone = window.URL_PRODUCT_REQUIRE_PHONE
            this.requireEmail=true 
            this.requireName=true
            this.showPicture=true 
            this.requirePassword=true
            this.requireBirthdate=true
            this.requireID=true
            //this.showPayment=true

            //if there is a deposit, that is our amt due, else use full cost
            if(this.selectedDeposit!=0){
                this.selectedPaymentAmount=this.selectedDeposit
            }else{this.selectedPaymentAmount=this.selectedCost}
            
            this.showPayment=this.selectedPaymentAmount>0 ? true:false


            this.addBadge = true
            this.GoTo('signinsignup','urlproduct')
        },
        continueAccountProduct(amtdue){
            this.selectedPaymentAmount=amtdue
            this.showPayment=true
            this.GoTo('picture','accountproduct')
        },
        confirmtrans(){
            
                this.GoTo('signinsignup','product')
            
            
        },
        saveperson(){
            if(this.isUrlProduct){this.saveTransTechInfo()}
            else if(this.isAccountProduct){this.GoTo('accountproduct','person')}
            else{this.GoTo('picture','person')}
        },

        //images
        pic_person(){
            this.havepic_person=true
        }, 
        pic_vac(){
            this.havepic_vac=true
        }, 
         pic_dlf(){
            this.havepic_dlf=true
        },
        pic_dlb(){
            this.havepic_dlb=true
        },
        gotImage_person(url){
             this.url_person=url
            this.GoTo('picture_vac','picture_person')
        },
        gotImage_vac(url){
            this.url_vac=url
            this.GoTo('picture_dlf','picture_vac')
        },
        gotImage_dlf(url){
            this.url_dlf=url
            this.GoTo('picture_dlb','picture_dlf')
        },
        gotImage_dlb(url){
            this.url_dlb=url
            this.GoTo('pay','picture_dlb')
        },


        selectcard(tokenid){
            this.selectedWalletTokenId=tokenid
            this.GoTo('accountsummary','card')
        },
        altpayment(){
            this.showPayment=false
            this.GoTo('thanks')
        },
        fundssubmitted(){
            console.log('fundssubmitted')
            this.GoTo('thanks')
        },
        nextsteps(){
            this.GoTo('nextsteps')
        },
        confirmthanks(){
            if(this.addBadge){
                if(window.MOBILEID_URL==''){
                    this.logout()
                }else{
                    let url = window.MOBILEID_URL
                    if(url!=undefined){
                        url = url + '?EmailAddress=' + this.username
                        window.location.href = url
                    }
                }
                
                
            }else{
                this.logout()
            }
            
        },

        logout(){
            console.log('logout')
            if(this.requireLogin){
                this.loggedIn=false
            }
     
            
            this.schoolLogo=''
            this.loggedInUser={}
            this.selectedProductGUID=null
            
            this.accountTransactionId=0
            this.GoTo('producttype')
        },

       
        async getLogoImageBasePath(){
            //call api
            var response = await this.callAPI_Image(null,'imagepath')
            //handle response
            if(response.Successful){
                this.logoImageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        },
        async refresh(){
            console.log('refresh')
            if(this.currentPanel=='card' ){
                this.$refs.cardscreen.getList()
            }else if(this.currentPanel=='multipay'){
                this.$refs.multipayscreen.getList()
            }
        },
        //for teh preselect of product from url
        async getProductbyGUID(){
            let req = {
                walletId:this.loggedInUser.walletId,
                locationId:this.loggedInUser.LocationId,
                productGUID:this.selectedProductGUID
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'products')

            //handle response
            if(response.Successful){
                this.productList = response.productList
                if(this.productList.length>0){

                    this.select(response.productList[0])
                }else{
                    //didn't work, don't go on to product
                    this.selectedProductGUID=null
                    this.GoTo(this.currentPanel)
                }
            
            }else{
                //didn't work, don't go on to product
                this.message = response.Message 
                this.selectedProductGUID=null
                this.GoTo(this.currentPanel)
            }
            this.pleaseWait=false;
        },
        async select(product){
            
            //send in to start transaction
            let req = {
                walletId:this.loggedInUser.walletId,
                accountId:this.loggedInUser.user_accountId,
                locationId:this.loggedInUser.LocationId,
                restrictToProductTypeGlobalKey:this.typeGUID,
                restrictToProductGlobalKey:product.guid,
                price:product.price,
                
            }
            if(this.selectedLocation!=null){
                req.locationName=this.selectedLocation
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'productsale')

            //handle response
            if(response.Successful){
              
                this.selectproduct(product,response.ResponseInteger)
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        },
        async saveTransTechInfo(){
            let req = {
            transTechSetupInfo:{
                accountId:this.loggedInUser.user_accountId,
                program:this.selectedProgram,
                location:this.selectedLocation,
                startDate:this.selectedDate + ' ' + this.selectedTime,
                cost:this.selectedCost,
                deposit:this.selectedDeposit
            } 
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'transtechinfo')
            //handle response
            if(response.Successful){
                this.GoTo('picture')
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },

        async CompleteNoPay(){
          console.log('CompleteNoPay')
            //setup request
            let req = {
                walletId:this.loggedInUser.walletId,
                accountId:this.loggedInUser.user_accountId,
                initialAccountFundList:this.accountList,
                accountTransactionId:this.accountTransactionId,
                locationId:this.loggedInUser.locationId
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response
            response = await this.callAPI(req,'paymenttoaccountlist_product_zeroPayment')
            
            //handle response
            if(response.Successful){
    
            }else{
                this.message="Your transaction failed to process."
            }
            this.pleaseWait=false;
        },
    },
    mounted(){
        var goAhead=true;

        console.log('loggedin' + this.loggedIn)
        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('email')
        if(tmp!=null){this.username = tmp; }

        tmp = urlParams.get('temp')
        if(tmp!=null){
          if(tmp.includes('otp')){
            this.password=tmp;
            goAhead=false;
            this.$nextTick(()=>{
              this.$refs.loginuser.forcelogin()
              
            });
          }
          else{
            console.log('in ch pass ')
            this.oldpassword = tmp;
            goAhead=false
            this.$nextTick(()=>{
            //this.changepass()
            this.$refs.loginuser.changepassword();
            
            });
          }
        }
        
        tmp = urlParams.get('product')
        if(tmp!=null){
           this.selectedProductGUID=tmp;
        }
        tmp = urlParams.get('logo')
        if(tmp!=null){ 
            this.logoOverride = tmp;
            this.getLogoImageBasePath();
        }

        tmp = urlParams.get('location')
        if(tmp!=null){
            //if it is an id/number, then use it as loc id
            if(tmp.isInteger){this.loggedInUser.LocationId=tmp;}
            //if it is a string, use it as sel location name
            else{this.selectedLocation = tmp}
        }

        if(goAhead){
            
        
        //transtech url params
        tmp = urlParams.get('program')
        if(tmp!=null){
            this.selectedProgram = tmp
            this.isUrlProduct=true
        }
        tmp = urlParams.get('startdate')
        if(tmp!=null){this.selectedDate = tmp}
        tmp = urlParams.get('starttime')
        if(tmp!=null){this.selectedTime = tmp}
        tmp = urlParams.get('cost')
        if(tmp!=null){this.selectedCost = parseFloat(tmp)}
        tmp = urlParams.get('deposit')
        if(tmp!=null){
            if(tmp!=''){
                this.selectedDeposit = parseFloat(tmp)
            }
            
        }
        }

        if(goAhead){
            //pay account params
            tmp = urlParams.get('account')
            if(tmp!=null){
                this.isAccountProduct=true
            }
        }
        

        this.setColors();

        if(goAhead){
            this.requireLogin=window.REQUIRE_LOGIN!='False'
            if(!this.requireLogin){ 
                this.loggedIn=true
                this.robot=true //we are going to need recaptch when entering person
                this.GoTo('location')
            }
        }
        

    },
    
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";
/* full screen */
@media screen and (min-width: 812px) {
    .outerDiv{
        background-color:var(--background-color);
        margin:0px 280px 0px 280px;
        height:98vh;
        display:grid;
        grid-template-columns:1fr 4fr 1fr;
        grid-template-rows:130px auto 105px;
        grid-template-areas: 
            "header header header"
            "center center center"
            "footer footer footer";
    }
    .entry{
        flex-direction: row;
    }
    .title{
        font-weight: bold;
        font-size:xx-large;
    }
    .productButton{
      font-size: large;
      width:360px;
    }
    
    
    .headertext{
      font-size:medium;
      margin:2px;
    }
    .headertextbold{
      font-size:medium;
      font-weight:bold;
      margin:2px;
    }
}
/* phone */
@media screen and (max-width: 812px) {
    .outerDiv{
        background-color:var(--background-color);
        margin:0px 20px 0px 20px;
        height:100vh;
        display:grid;
        grid-template-columns:1fr 4fr 1fr;
        grid-template-rows:100px auto 130px;
        grid-template-areas: 
            "header header header"
            "center center center"
            "footer footer footer";
    }
    .entry{
        flex-direction: column;
    }
    .title{
        font-weight: bold;
        font-size:x-large;
        
    }
    .productButton{
      font-size:medium;
      width:90%;
    }
    .loginlogo{
      height:55px;
      margin-right:0px;
      margin-top:10px;
    }
    .loginlogo2{
      height:45px;
      margin-left:10px;
      
    }
    .logo{
      height:55px;
      margin-top:10px;
    }
    .headertext{
      font-size:small;
      margin:2px;
    }
    .headertextbold{
      font-size:small;
      font-weight:bold;
      margin:2px;
    }
}

.mainDiv{
    background-color:var(--frame-color);
    grid-area: center;
    // background-color:#CACACA;
    padding:10px;
    
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-items:space-between;
}
.header{
    grid-area:header;
    padding:10px 0px 10px 0px ;
}
.panel{
    background-color:var(--frame-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}
.subtitleMain{
  font-size:large;
    margin:5px 0px 0px 0px;
    width:70%;
}
.backButton{
  color:#035891;
  background-color:transparent;
  text-decoration: underline;
  font-size:large;
  font-weight: bold;
  border:none;
  text-align:center;
  margin:0px;

}
.backButton:hover{
  cursor:pointer;
  background-color:transparent;
}
.htmlLabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:large;
}
</style>